@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');



*{
  margin: 0;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}
::selection {
  background: #422861;
  color: #fff;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Raleway", sans-serif;
  background-color: #f1f1f2; 
  /* overflow-y: hidden; */
   
  /* font-family: 'Work Sans', sans-serif; */
  /* background-image: url(../public/imgs/partypic3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  /* height: 100vh; */
}
li{
  user-select: none;
}
#search-here::placeholder {
  color: #ffffff;
  opacity: 1; /* Firefox */
}

#search-here::-ms-input-placeholder { /* Edge 12 -18 */
  color: #ffffff;
}

::placeholder {
  color: #999999;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #777777;
}



.df {
  display: flex;
  justify-content: center;
  align-items: center;
}

#hero-section{
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    background: url(/public/imgs/pattern.jpg);
}
@media (max-width: 900px) {
  #hero-section{
    height: 480px;
  }
}
@media (max-width: 580px) {
  #hero-section{
    height: 537px;
  }
}
#counter{
  position: absolute;
  opacity: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  z-index: 20;
  width: 36px;
  height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
  font-size: 18px;
  font-weight: 300;
  transition: all 0.3s linear 0s;
}
#hero-section:hover  #counter{
  opacity: 100;
}
@media (max-width: 600px) {
  #counter{
    bottom: 10px;
    right: 80px;
  }
}
@media (max-width: 580px) {
  #counter{
    bottom: 10px;
    right: 80px;
  }
}


#content-new-site a:focus {
  outline: 1px dashed #333 !important;
}

/* Customize the navigation arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 50%;
  left: 0;
  margin-top: -26px;
  width: 52px;
  height: 52px;
  border: 0;
  transition: all 0.3s linear;
  background-color: rgba(66, 33, 100, 0.8);
  border-radius: 0 100% 100% 0;
}


/* Nav Icon change onHover */

#hover-icon {
  display: none; /* Initially hide the hover icon */
}

#icon-container:hover #normal-icon {
  display: none; /* Hide the normal icon on hover */
}

#icon-container:hover #hover-icon {
  display: block; /* Show the hover icon on hover */
}



/* linkCardIconHOver */
#linkIconHolder:hover > #linkCard{
  color: #000 !important;
}

#linkIconHolder:hover   #linkCard2{
  filter: grayscale(100%) brightness(0%) contrast(100%) opacity(10) !important;
}



/* Small Screen login text */
#dt-only{
  display: inline;
}
#dt-only2{
  display: none;
}
@media (max-width: 580px) {
  #dt-only{
    display: none;
  }
  #dt-only2{
    display: inline;
  }
}


.cartItemsHolder > :last-child{
  border-bottom: none !important;
}


/* Random */

.tipue_search_content_loc{
  padding-left: 0.6rem;
  margin: 0.3rem 0;  
  font-size: 0.874rem;
  color: #555;
}



@media (max-width: 900px) {
  .cardSidesHolder{
    flex-direction: column-reverse !important;
  }
}
